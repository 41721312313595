.cardContainer {
    width: 240px;
    height: 300px;
    overflow: hidden;
    border-radius: 15px;
}

.cardContainer img {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
}