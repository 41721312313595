.introduction {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iParagraph {
    text-align: center;
    line-height: 1.5;
}

.mainTitle {
    font-size: 45px;
}