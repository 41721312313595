@import url('https://fonts.cdnfonts.com/css/product-sans');

.navContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
}

.navLogo {
    width: 200px;
    height: auto;
}

.navLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    font-family: 'Product Sans', Arial, Helvetica, sans-serif;
}

.navLinks a {
    text-decoration: none;
    color: black;
}