* {
    font-family: 'Product Sans', Arial, Helvetica, sans-serif;
}

* button {
    font-size: 14px;
    
}

.welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('../assets/mansion.webp');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    border-radius: 15px;
}

.title {
    font-family: 'Product Sans', Arial, Helvetica, sans-serif;
    font-size: 70px;
    color: white;
}

.actionButton {
    width: 144px;
    height: 48px;
    border-radius: 100px;
    background-color: white;
    border: none;
}

.infoSection {
    margin-left: 45px;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoTitle {
    font-size: 45px;
}

.infoText {
    width: 70%;
    text-align: center;
    line-height: 1.8;
}

.cardSection {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 0px;
}

.callingContainer {
    display: flex;
    background: url('../assets/happiness.webp');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    border-radius: 15px;
    margin-top: 45px;
}

.callingTitle {
    margin-top: 90px;
    margin-left: 90px;
    font-size: 45px;
    color: white;
}

.callingSub {
    color: white;
    width: 70%;
    line-height: 1.5;
    margin-left: 90px;
}

.callingButton {
    width: 144px;
    height: 48px;
    border-radius: 100px;
    background-color: white;
    border: none;
    margin-left: 90px;
    margin-top: 45px;
}