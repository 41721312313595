.contactBody {
    display: flex;
    flex-direction: column;
}

.contactWelcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    font-size: 45px;
}

.contactForm {
    width: 650px;
    margin: 0 auto;
    margin-top: 90px;
    
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    font-weight: normal;
  }
  
  input,
  textarea {
    width: 100%;
    height: 25px;
    padding: 0.5rem;
    border: none;
    background-color: #ffffff;
    border-radius: 15px;
    border: 1px solid black;
    margin-top: 10px;
    font-size: 12px;
  }
  
  .submit-button {
    width: 100px;
    height: 40px;
    background-color: #000000;
    color: #fff;
    font-size: 15px;
    padding: 0.5rem 1rem;
    border-radius: 100px;
    border: none;
  }

  @media (max-width: 767px) {
    .contactForm {
      max-width: 80%;
    }
}